var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "bgcenters" },
      [
        _c("van-nav-bar", {
          attrs: {
            "left-arrow": "",
            fixed: "",
            border: false,
            "safe-area-inset-top": "",
            placeholder: "",
            title: _vm.orderType == "PRE_MORTGAGE" ? "存料预付" : "存料结算"
          },
          on: { "click-left": _vm.leftClick }
        })
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "centerboxs" },
          [
            _c("div", { staticClass: "topbg" }),
            _c(
              "div",
              { staticClass: "orderbox" },
              [
                _c(
                  "center",
                  [
                    _vm.StockData.status == "2"
                      ? _c("h1", { staticClass: "f32" }, [
                          _vm._v(" 请确认下方结算信息 ")
                        ])
                      : _vm._e(),
                    _vm.StockData.status == "1"
                      ? _c("h1", { staticClass: "f32" }, [
                          _vm._v(" 打款已完成，请查收 ")
                        ])
                      : _vm._e(),
                    _vm.StockData.status == "0"
                      ? _c("h1", { staticClass: "f32" }, [
                          _vm._v("您的款项正在飞速打款中....")
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "money" }, [
                      _c("b", { staticClass: "f32" }, [
                        _vm._v(
                          "￥" +
                            _vm._s(
                              _vm.orderType == "PRE_MORTGAGE"
                                ? _vm.StockData.moneyTotal
                                : _vm.StockData.totalMoney
                            )
                        )
                      ])
                    ]),
                    _c(
                      "el-button",
                      {
                        staticClass: "btnsee f32",
                        on: { click: _vm.dianzidanju }
                      },
                      [_vm._v("查看电子单据")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "topcenter" }, [
              _vm.StockData.status == "2"
                ? _c("div", [
                    _c("div", { staticClass: "toptitle" }, [
                      _vm._v("物料明细")
                    ]),
                    _c(
                      "div",
                      [
                        _vm._l(_vm.StockData.list_wuLiao, function(
                          item,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "flex-between" },
                            [
                              _c("div", [_vm._v(_vm._s(item.name))]),
                              _c("div", [_vm._v(_vm._s(item.weight) + "克")])
                            ]
                          )
                        }),
                        _c("div", { staticClass: "flex-between" }, [
                          _c("div", [_vm._v("物料总重")]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.StockData.weight) + "克")
                          ])
                        ]),
                        _c("div", { staticClass: "flex-between" }, [
                          _c("div", [_vm._v("物料折足总重")]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.StockData.weightZeZu) + "克")
                          ])
                        ])
                      ],
                      2
                    )
                  ])
                : _vm._e(),
              _c("div", [
                _c("div", { staticClass: "toptitle mart" }, [
                  _vm._v("结算明细")
                ]),
                _c("div", { staticClass: "sectionbox" }, [
                  _c("div", { staticClass: "flex-betweens" }, [
                    _c("div", [_vm._v("存料克重:")]),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          (_vm.StockData.status == "2" ||
                            _vm.StockData.status == "0") &&
                            _vm.orderType == "PRE_MORTGAGE"
                            ? _vm.StockData.weightZeZu
                            : _vm.StockData.weight
                        ) + "克"
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "flex-betweens" }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.orderType == "PRE_MORTGAGE"
                            ? "预估金价:"
                            : "结算金价:"
                        )
                      )
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.orderType == "PRE_MORTGAGE"
                            ? _vm.StockData.priceYuGu
                            : _vm.StockData.pricePinCang
                        ) + "克/元"
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "flex-betweens" }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.orderType == "PRE_MORTGAGE"
                            ? "预估价值:"
                            : "结算价值:"
                        )
                      )
                    ]),
                    _c("div", { staticClass: "blackFont" }, [
                      _vm._v(
                        _vm._s(
                          _vm.orderType == "PRE_MORTGAGE"
                            ? _vm.StockData.moneyYuGu
                            : _vm.StockData.moneyPinCang
                        ) + "克"
                      )
                    ])
                  ])
                ])
              ]),
              _vm.orderType == "PRE_MORTGAGE"
                ? _c("div", [
                    _c("div", { staticClass: "toptitle mart" }, [
                      _vm._v("存料预付")
                    ]),
                    _c("div", { staticClass: "sectionbox" }, [
                      _c("div", { staticClass: "flex-betweens" }, [
                        _c("div", [_vm._v("预付比例:")]),
                        _c("div", { staticClass: "blackFont" }, [
                          _vm._v(_vm._s(_vm.StockData.percentageYuFu) + "%")
                        ])
                      ]),
                      _c("div", { staticClass: "flex-betweens" }, [
                        _c("div", [_vm._v("预付金额:")]),
                        _c("div", { staticClass: "orangeColor" }, [
                          _vm._v(_vm._s(_vm.StockData.moneyYuFu) + "元")
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm.orderType != "PRE_MORTGAGE"
                ? _c("div", [
                    _c("div", { staticClass: "toptitle mart" }, [
                      _vm._v("存料结算")
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "flex-between" }, [
                        _c("div", [_vm._v("其它费用：")]),
                        _c("div", { staticClass: "blackFont" }, [
                          _vm._v(_vm._s(_vm.StockData.moneyTiChun) + "元")
                        ])
                      ]),
                      _c("div", { staticClass: "flex-between" }, [
                        _c("div", [_vm._v("快递费：")]),
                        _c("div", { staticClass: "blackFont" }, [
                          _vm._v(_vm._s(_vm.StockData.moneyYunFei) + "元")
                        ])
                      ]),
                      _c("div", { staticClass: "flex-between" }, [
                        _c("div", [_vm._v("保险费：")]),
                        _c("div", { staticClass: "blackFont" }, [
                          _vm._v(_vm._s(_vm.StockData.moneyBaoXiang))
                        ])
                      ]),
                      _c("div", { staticClass: "flex-between" }, [
                        _c("div", [_vm._v("累计仓租:")]),
                        _c("div", { staticClass: "blackFont" }, [
                          _vm._v(_vm._s(_vm.StockData.moneyStore))
                        ])
                      ]),
                      _c("div", { staticClass: "flex-between" }, [
                        _c("div", [_vm._v("累计补仓:")]),
                        _c("div", { staticClass: "blackFont" }, [
                          _vm._v(_vm._s(_vm.StockData.moneyBuCang) + "元")
                        ])
                      ]),
                      _c("div", { staticClass: "flex-between" }, [
                        _c("div", [_vm._v("存料预付:")]),
                        _c("div", { staticClass: "blackFont" }, [
                          _vm._v(_vm._s(_vm.StockData.moneyYuFu) + "元")
                        ])
                      ]),
                      _c("div", { staticClass: "flex-between" }, [
                        _c("div", [_vm._v("剩余应付:")]),
                        _c("div", { staticClass: "orangeColor" }, [
                          _vm._v(_vm._s(_vm.StockData.moneyShenYu) + "元")
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _c("div", { staticClass: "toptitle mart  info" }, [
                _vm._v("订单信息")
              ]),
              _c("div", [
                _c("div", { staticClass: "flex-between_ flex" }, [
                  _c("div", [_vm._v("顺丰单号：")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.StockData.orderNoExpress
                          ? _vm.StockData.orderNoExpress
                          : "--"
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "flex-between_ flex" }, [
                  _c("div", [_vm._v("结算单号：")]),
                  _c("div", [_vm._v(_vm._s(_vm.StockData.orderNo))])
                ]),
                _c("div", { staticClass: "flex-between_ flex" }, [
                  _c("div", [_vm._v("开单时间：")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.StockData.status == "2"
                          ? _vm.StockData.date
                          : _vm.StockData.dateYaPan
                      )
                    )
                  ])
                ]),
                _vm.StockData.status != "2"
                  ? _c("div", { staticClass: "flex-between_ flex" }, [
                      _c("div", [_vm._v("结算时间：")]),
                      _c("div", [_vm._v(_vm._s(_vm.StockData.datePinCang))])
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "flex-between_ flex" }, [
                  _c("div", [_vm._v("用户姓名：")]),
                  _c("div", [_vm._v(_vm._s(_vm.StockData.name))])
                ]),
                _c("div", { staticClass: "flex-between_ flex" }, [
                  _c("div", [_vm._v("联系电话：")]),
                  _c("div", [_vm._v(_vm._s(_vm.StockData.phone))])
                ]),
                _c("div", { staticClass: "flex-between_ flex" }, [
                  _c("div", [_vm._v("收款账号：")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.StockData.bankName + _vm.StockData.bankNum)
                    )
                  ])
                ]),
                _c("div", { staticClass: "flex-between_ flex" }, [
                  _c("div", [_vm._v("备注信息：")]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.StockData.info ? _vm.StockData.info : "--")
                    )
                  ])
                ])
              ])
            ]),
            _vm.StockData.status == "2"
              ? _c(
                  "center",
                  { staticClass: "rel" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_red onlybox",
                        on: { click: _vm.orderObjection }
                      },
                      [_vm._v("对结算单有异议？")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_red onlyboxsecond_yellow",
                        on: { click: _vm.confirm }
                      },
                      [_vm._v("确认无误")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.StockData.status == "0"
              ? _c(
                  "center",
                  { staticClass: "rel" },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "btn_red onlybox",
                        on: { click: _vm.orderObjection }
                      },
                      [_vm._v("对结算单有异议？")]
                    ),
                    _c(
                      "el-button",
                      { staticClass: "btn_red overconfirm onlyboxsecond" },
                      [_vm._v("已确认")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        ),
        _c(
          "van-popup",
          {
            model: {
              value: _vm.popupbox,
              callback: function($$v) {
                _vm.popupbox = $$v
              },
              expression: "popupbox"
            }
          },
          [
            _c("div", { staticClass: "titlebox_popup" }, [_vm._v("提示")]),
            _c("div", { staticClass: "detailsbox" }, [
              _vm._v(
                " 如结算单有误需拨打" +
                  _vm._s(_vm.GLOBAL) +
                  "客服人工核对修改，是否拨打" +
                  _vm._s(_vm.GLOBAL) +
                  "客服电话：400-850-6718 "
              )
            ]),
            _c("div", { staticClass: "flex btn-flex rel" }, [
              _c(
                "div",
                {
                  staticClass: "canclebox",
                  on: {
                    click: function($event) {
                      _vm.popupbox = !_vm.popupbox
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c("div", { staticClass: "RedF", on: { click: _vm.playPhone } }, [
                _vm._v("确认")
              ])
            ])
          ]
        ),
        _c(
          "van-popup",
          {
            model: {
              value: _vm.iscomfirm,
              callback: function($$v) {
                _vm.iscomfirm = $$v
              },
              expression: "iscomfirm"
            }
          },
          [
            _c("div", { staticClass: "titlebox_popup" }, [_vm._v("结算确认")]),
            _c("div", { staticClass: "detailsbox" }, [
              _vm._v(
                " 尊敬的用户，您正在进行结算确认，请仔细核对您的来料重量与结算价格。确认无误后，" +
                  _vm._s(_vm.GLOBAL) +
                  "将在一个工作日内完成打款。 "
              )
            ]),
            _c("div", { staticClass: "flex btn-flex rel" }, [
              _c(
                "div",
                {
                  staticClass: "canclebox",
                  on: {
                    click: function($event) {
                      _vm.iscomfirm = !_vm.iscomfirm
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c("div", { staticClass: "RedF", on: { click: _vm.querenbox } }, [
                _vm._v("确认")
              ])
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }